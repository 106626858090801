import * as Yup from 'yup';

const UpdateBoomiSystemSchema = Yup.object().shape({
  // Connection Details
  name: Yup.string()
    .required('Name is required')
    .matches(
      /^(?!\s*$).+/,
      'Name must not be empty'
    ),
  description: Yup.string()
    .required('Description is required')
    .max(255, 'Description can’t be longer than 255 characters'),
  atom_id: Yup.string()
    .required('Atom ID is required')
    .uuid('Atom ID must be a valid UUID'),
  account_id: Yup.string()
    .required('Account ID is required'),
  email: Yup.string()
    .required('Email is required')
    .email('Must be a valid email'),
  password: Yup.string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters long'),
});

export default UpdateBoomiSystemSchema;
