import * as yup from 'yup';

const OSSystemSchema = yup.object().shape({
  name: yup.string().required('Name is required'),
  description: yup.string().required('Description is required'),
  host: yup.string().required('Host  is required'),
  port: yup.string().transform((value, originalValue) => (originalValue === '' ? null : value)).nullable().required('Port is required'),
  user: yup.string().required('User is required'),
  password: yup.string().required('Passowrd is required'),
  provider: yup.string().required('Provider is required'),
  os_type: yup.string().required('OS Type is required'),
});

export default OSSystemSchema;