import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import TicketingServices from "./ticketingServices";

const useTicketing = () => {
  const queryClient = useQueryClient();

  const { data: toolData = [], isLoading: isToolLoading, isError: isToolError } = useQuery({
    queryKey: ["toolData"],
    queryFn: TicketingServices.getTool,
    retry: 0,
  });

  const { mutate: updateToolState } = useMutation({
    mutationFn: ({ id, reqBody }) => TicketingServices.updateTool(id, reqBody),
    onSuccess: () => {
      queryClient.invalidateQueries(["toolData"]);
    }
  });

  const toggleToolState = (toolId, currentState) => {
    updateToolState({
      id: toolId,
      reqBody: { state: !currentState }, 
    });
  };

  
  const activeTool = toolData.find((tool) => tool.state);

  const ticketStatusTo =
    activeTool?.name === "jira"
      ? "/tickets/jira"
      : activeTool?.name === "servicenow"
      ? "tickets/service-now"
      : null;


  return {
    toolData,
    isToolLoading,
    isToolError,
    toggleToolState,
    ticketStatusTo,
  };
};

export default useTicketing;
