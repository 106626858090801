import * as Yup from 'yup';

const UpdateOSSystemSchema = Yup.object().shape({
  // Connection Details
  name: Yup.string()
    .required('Name is required'),

  description: Yup.string()
    .required('Description is required'),

  provider: Yup.string()
    .required('Provider is required'),

  os_type: Yup.string()
    .required('OS Type is required'),

  host: Yup.string()
    .required('Host is required')
    .matches(
      /^(?!:\/\/)([a-zA-Z0-9-_]+\.)*[a-zA-Z0-9][a-zA-Z0-9-_]+\.[a-zA-Z]{2,11}$|^(\d{1,3}\.){3}\d{1,3}$/,
      'Invalid host format'
    ),

  port: Yup.number()
    .required('Port is required')
    .min(1, 'Port must be a valid number between 1 and 65535')
    .max(65535, 'Port must be a valid number between 1 and 65535'),

  user: Yup.string()
    .required('User is required'),

  password: Yup.string()
    .required('Password is required'),
});

export default UpdateOSSystemSchema;
