import axios from "axios";
import { applyInterceptors } from "../helpers";

const httpExtractor = axios.create({
  baseURL: process.env.REACT_APP_EXTRACTOR_PATH,
  withCredentials: true
});

applyInterceptors(httpExtractor, {
  tokenPrefix: process.env.REACT_APP_EXTRACTOR_PREFIX,
  tokenHeader: 'conn-auth',
  tokenType : "Bearer" 
});

export default httpExtractor;