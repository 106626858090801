import axios from "axios";
import { applyInterceptors } from "../helpers";

const httpMain = axios.create({
  baseURL: process.env.REACT_APP_CORE_PATH,
  withCredentials: true
});

applyInterceptors(httpMain, {
  tokenPrefix: process.env.REACT_APP_DISPATCHER_PREFIX,
  tokenHeader: 'Authorization',
  tokenType : 'Bearer'
});

export default httpMain;