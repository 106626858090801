import React, { useState } from "react";
import PageLayout from "../../../components/PageLayout/PageLayout";
import Panel from "./Panel/Panel";
import SapSystemForm from "./SapSystemForm/SapSystemForm";
import useAddSystem from "./logic/useAddSystem";
import { toast } from "react-toastify";
import OSSystemForm from "./OSSystemForm/OSSystemForm";
import BoomiSystemForm from "./BoomiSystemForm/BoomiSystemForm";

const AddCortexFlowSystem = () => {
  const [selectedConnectionType, setSelectedConnectionType] = useState(""); // Track selected connection type
  const [steps, setSteps] = useState([
    { id: "1", name: "Connection Type", status: "current" },
    { id: "2", name: "Connection Details", status: "upcoming" },
  ]);

  // Handle select change
  const handleSelectChange = (e) => {
    const selectedValue = e.target.value;
    setSelectedConnectionType(selectedValue);

    setSteps((prevSteps) =>
      prevSteps.map((step) => ({
        ...step,
        status: step.id === "1" ? "complete" : "current",
      }))
    );
  };

  const {
    isLoading,
    systemIsLoading,
    categoryIsLoading,
    error,
    onSubmit,
    register,
    handleSubmit,
    isSubmitted,
    errors,
    navigateTo,
    handleAddCategoryClick,
    showSlideOver,
    setShowSlideOver,
    envData,
    categoryData,
    clearErrors,
    setAddedCategory,
    testConnectionStatus,
    categoryError,
    reset,
  } = useAddSystem(selectedConnectionType);

  const handleStepClick = (stepId) => {
    if (stepId === "2" && !selectedConnectionType) {
      toast.error("Please select a valid connection type to proceed.");
      return;
    }
    if (stepId === "1") {
      setSelectedConnectionType("");
    }
    setSteps((prevSteps) =>
      prevSteps.map((step) => ({
        ...step,
        status:
          step.id === stepId
            ? "current"
            : step.id < stepId
              ? "complete"
              : "upcoming",
      }))
    );
  };

  const showConnectionTypeStep = steps[0]?.status === "current";

  return (
    <PageLayout
      pageName="Create connection"
      pageClass="AddSystemPage"
      redirectBack="/cortexFlow/connections"
      navigateTo={navigateTo}
    >
      <Panel steps={steps} onStepClick={handleStepClick} />
      {showConnectionTypeStep && (
        <div className="pt-4 pb-10 px-4 overflow-hidden rounded-lg border sm:rounded-md dark:bg-secondary-bg-color custom-scrollbar">
          <label htmlFor="type" className="block text-sm font-medium text-app-gray-700">
            Connection Type <span className="text-app-red-color">*</span>
          </label>
          <select
            className="w-full mt-1 block rounded-md border border-app-gray-300 shadow-sm
              focus:border-app-gray-300 focus:ring-indigo-500 sm:text-sm text-app-gray-700 pr-16"
            value={selectedConnectionType}
            onChange={handleSelectChange}
          >
            <option disabled value="">
              -- Select Connection Type --
            </option>
            <option value="SAP">SAP</option>
            <option value="OS">OS</option>
            <option value="Boomi">Boomi</option>
          </select>
        </div>
      )}
      {!showConnectionTypeStep && selectedConnectionType === "SAP" && (
        <SapSystemForm
          isLoading={isLoading}
          systemIsLoading={systemIsLoading}
          categoryIsLoading={categoryIsLoading}
          error={error}
          onSubmit={onSubmit}
          register={register}
          handleSubmit={handleSubmit}
          isSubmitted={isSubmitted}
          errors={errors}
          clearErrors={clearErrors}
          navigateTo={navigateTo}
          envData={envData}
          categoryData={categoryData}
          showSlideOver={showSlideOver}
          setShowSlideOver={setShowSlideOver}
          handleAddCategoryClick={handleAddCategoryClick}
          setSelectedConnectionType={setSelectedConnectionType}
          setAddedCategory={setAddedCategory}
          testConnectionStatus={testConnectionStatus}
          categoryError={categoryError}
          reset={reset}
          selectedConnectionType={selectedConnectionType}
        />
      )}
      {!showConnectionTypeStep && selectedConnectionType === "OS" && (
        <OSSystemForm
          isLoading={isLoading}
          onSubmit={onSubmit}
          register={register}
          handleSubmit={handleSubmit}
          isSubmitted={isSubmitted}
          errors={errors}
          clearErrors={clearErrors}
          navigateTo={navigateTo}
          handleAddCategoryClick={handleAddCategoryClick}
          setSelectedConnectionType={setSelectedConnectionType}
          reset={reset}
          selectedConnectionType={selectedConnectionType}
        />

      )}
      {!showConnectionTypeStep && selectedConnectionType === "Boomi" && (
        <BoomiSystemForm
          isLoading={isLoading}
          onSubmit={onSubmit}
          register={register}
          handleSubmit={handleSubmit}
          isSubmitted={isSubmitted}
          errors={errors}
          clearErrors={clearErrors}
          navigateTo={navigateTo}
          handleAddCategoryClick={handleAddCategoryClick}
          setSelectedConnectionType={setSelectedConnectionType}
          reset={reset}
          selectedConnectionType={selectedConnectionType}
        />

      )}
    </PageLayout>
  );
};

export default AddCortexFlowSystem;