import * as Yup from 'yup';

const UpdateSAPSystemSchema = Yup.object().shape({
  // Connection Details
  sid: Yup.string()
    .required('SID is required')
    .length(3, 'SID must be exactly 3 characters long'),
  description: Yup.string()
    .required('Description is required'),
  lang: Yup.string()
    .required('Language is required'),
  client: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .nullable()
    .required('Client is required'),
  system_category_id: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .nullable()
    .required('Category is required'),
  system_env_id: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .nullable()
    .required('Environment is required'),

  // Instance Details
  instanceNumber: Yup.string()
    .required('Instance Number is required'),
  instanceDescription: Yup.string()
    .required('Instance Description is required'),
  instanceHost: Yup.string()
    .required('Instance Host is required'),
  instanceId: Yup.string()
    .required('Instance ID is required'),

  // User Credentials
  username: Yup.string()
    .required('Username is required'),
  password: Yup.string()
    .required('Password is required'),
  basisUserId: Yup.string()
    .required('Basis User ID is required'),
});

export default UpdateSAPSystemSchema;
