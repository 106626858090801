import InputField from "../../../../components/AppInputForm/InputField/InputField";
import PrimaryButton from "../../../../components/Buttons/PrimaryButton";
import TransparentButton from "../../../../components/Buttons/TransparentButton";
import { IconCheck, IconX } from "../../../../components/_Catalog/icons";
import useAddCategory from "./logic/useAddCategory";

const AddCategory = ({ showSlideOver, setShowSlideOver, setAddedCategory }) => {

  const {
    isLoading,
    error,
    onSubmit,
    register,
    handleSubmit,
    isSubmitted,
    errors,
  } = useAddCategory(setShowSlideOver, setAddedCategory);

  const handleClickOutside = (event) => {
    if (event.target === event.currentTarget) {
      setShowSlideOver(false);
    }
  };


  return (
    <div className={`fixed inset-0 overflow-hidden z-20 ${showSlideOver ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'}`}>
      <div className="absolute inset-0 overflow-hidden" onClick={handleClickOutside}>
        <div className="fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
          <div className="pointer-events-auto w-screen max-w-md opacity-100">
            <form className="flex h-full flex-col divide-y divide-gray-200 bg-app-white-color dark:bg-secondary-bg-color shadow-xl rounded-l-xl" onSubmit={handleSubmit(onSubmit)}>
              <div className="h-0 flex-1 overflow-y-auto">
                <div className="bg-primary-color py-6 px-4 sm:px-6 rounded-tl-xl">
                  <div className="flex items-center justify-between">
                    <h3 className="text-lg font-medium text-app-white-color dark:text-primary-text-color">New Category</h3>
                    <div className="ml-3 flex h-7 items-center">
                      <button
                        type="button"
                        className="rounded-md bg-primary-color text-indigo-200 hover:text-app-white-color 
                        focus:outline-none focus:ring-2 focus:ring-app-white-color"
                        onClick={() => setShowSlideOver(false)}
                      >
                        <span className="sr-only">Close panel</span>
                        <IconX className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                </div>
                <div className="flex flex-1 flex-col justify-between">
                  <div className="divide-y divide-gray-200 px-4 sm:px-6">
                    <div className="pt-6 pb-5">
                      <div className='mb-4'>
                        <InputField
                          name="name"
                          label="Category name"
                          placeholder="Enter category name"
                          error={error}
                          errors={isSubmitted && errors.name}
                          register={register}
                        />
                      </div>
                      <div className='mb-4'>
                        <InputField
                          name="description"
                          label="Category description"
                          placeholder="Enter category description"
                          error={error}
                          errors={isSubmitted && errors.description}
                          register={register}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-shrink-0 justify-end px-4 py-4">
                <div className="mr-4">
                <TransparentButton buttonEvent={() => setShowSlideOver(false)} iconButton={<IconX sm={true} />} buttonText="Cancel" />
                </div>
                <PrimaryButton type="submit" isLoading={isLoading} buttonText="Submit" iconButton={<IconCheck />} />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCategory;
