import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import CategorySchema from './CategorySchema';
import systemsService from '../../../logic/systemsService';

const useAddCategory = (setShowSlideOver, setAddedCategory) => {
  const { register, handleSubmit, formState: { isSubmitted, errors }, clearErrors } = useForm({ resolver: yupResolver(CategorySchema) });

  const [isLoading, setIsLoading] = useState('');
  const [error, setError] = useState('');

  const navigateTo = useNavigate();

  const addCategory = async (data) => {
    setError('');
    setIsLoading(true);

    try {
      const response = await systemsService.addSystemCategory(data);
      if (response.data) {
        setAddedCategory(response.data)
        toast.success("System category has been added successfully");
        setShowSlideOver(false);
      }
    } catch (error) {
      console.error('API ERROR :', error);
      setError('An error occurred while adding the system category.');
      toast.error('An error occurred while adding system category.');
    } finally {
      setIsLoading(false);
    }
  };

  const onSubmit = (formData) => addCategory(formData);

  return {
    isLoading,
    error,
    onSubmit,
    register,
    handleSubmit,
    isSubmitted,
    errors,
    clearErrors,
    navigateTo,
  };
};

export default useAddCategory;
