import React from "react";
import PrimaryButton from "../../../../components/Buttons/PrimaryButton";
import TransparentButton from "../../../../components/Buttons/TransparentButton";
import InputField from "../../../../components/AppInputForm/InputField/InputField";
import { IconCheck, IconX } from "../../../../components/_Catalog/icons";
import { Link } from "react-router-dom";

const UpdateOSSystemForm = ({
    data,
    onSubmit,
    register,
    handleSubmit,
    isLoading,
    isSubmitted,
    errors,
    error
}) => {
    if (error) return <div className="text-2xl text-app-red-color text-center">{error}</div>
    return (
        <div className="md:col-span-2 md:mt-0">
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="overflow-x-auto max-h-[82vh] rounded-xl border bg-transparent-dark">
                    <div className={"px-4 sm:px-6"}>
                        <div className="px-6 pb-6 border-b mb-3">
                            <span className="pb-4 block text-xl text-app-gray-700">Connection Details</span>
                            <div className="grid grid-cols-6 gap-6 mt-2 mb-6">
                                <InputField
                                    name="name"
                                    label="Connection Name"
                                    placeholder="Enter connection name"
                                    defaultValue={data?.name}
                                    errors={isSubmitted && errors.name}
                                    register={register}
                                    isLoading={isLoading}
                                />
                                <InputField
                                    name="description"
                                    label="Description"
                                    placeholder="Enter description"
                                    defaultValue={data?.description}
                                    errors={isSubmitted && errors.description}
                                    register={register}
                                    isLoading={isLoading}
                                />
                                <InputField
                                    name="provider"
                                    label="Provider"
                                    defaultValue={data?.provider}
                                    errors={isSubmitted && errors.provider}
                                    register={register}
                                    isLoading={isLoading}
                                />
                                <InputField
                                    name="os_type"
                                    label="OS Type"
                                    placeholder="Enter OS Type"
                                    defaultValue={data?.os_type}
                                    errors={isSubmitted && errors.os_type}
                                    register={register}
                                    isLoading={isLoading}
                                />
                                <InputField
                                    name="host"
                                    label="Host"
                                    placeholder="Enter host"
                                    defaultValue={data?.host}
                                    errors={isSubmitted && errors.host}
                                    register={register}
                                    isLoading={isLoading}
                                />
                                <InputField
                                    name="port"
                                    label="Port"
                                    type="number"
                                    placeholder="Enter port"
                                    defaultValue={data?.port}
                                    errors={isSubmitted && errors.port}
                                    register={register}
                                    isLoading={isLoading}
                                />
                                <InputField
                                    name="user"
                                    label="User"
                                    placeholder="Enter user"
                                    defaultValue={data?.user}
                                    errors={isSubmitted && errors.user}
                                    register={register}
                                    isLoading={isLoading}
                                />
                                <InputField
                                    name="password"
                                    label="Password"
                                    type="password"
                                    placeholder="Enter password"
                                    defaultValue={data?.password}
                                    errors={isSubmitted && errors.password}
                                    register={register}
                                    isLoading={isLoading}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="flex sticky bottom-0 flex-row-reverse gap-3 bg-[#F7F7F7] dark:bg-secondary-bg-color px-4 py-3 sm:px-6">
                        <PrimaryButton type="submit" isLoading={isLoading} buttonText="Update" iconButton={<IconCheck />} />
                        <Link to="/cortexFlow/connections"><TransparentButton buttonText="Cancel" iconButton={<IconX sm color="var(--secondary-color)" />} /></Link>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default UpdateOSSystemForm;
