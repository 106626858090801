import React, { useEffect, useState, useCallback } from "react";
import { IconBackSpace, IconSpinner } from "../../_Catalog/icons";

const SelectField = ({
  name,
  label,
  options,
  register,
  error,
  errors,
  clearErrors,
  isLoading,
  disabled,
  handleSelectChange,
  selectioName,
  erraseButton,
  defaultValue,
  onChange,
}) => {
  const [selectedValue, setSelectedValue] = useState(defaultValue || '');

  const handleChange = useCallback((e) => {
    const value = e.target.value;
    setSelectedValue(value);
    if (clearErrors) {
      clearErrors(name);
    }
    if (handleSelectChange) {
      handleSelectChange(selectioName, value);
    }
    if (onChange) onChange(e);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearErrors, handleSelectChange, name, selectioName]);

  useEffect(() => {
    setSelectedValue(defaultValue || '');
  }, [defaultValue]);

  if (error) {
    return (
      <div className="col-span-6 sm:col-span-3 w-full relative">
        {label && (
          <label htmlFor={name} className="block text-sm font-medium text-app-gray-700">
            {label} <span className="text-app-red-color">*</span>
          </label>
        )}
        <div className="mt-1 w-full rounded-md border border-app-gray-300 shadow-sm sm:text-sm text-app-gray-700 h-[38px] p-2 bg-red-400">
          {error}
        </div>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="col-span-6 sm:col-span-3 w-full relative">
        {label && (
          <label htmlFor={name} className="block text-sm font-medium text-app-gray-700">
            {label} <span className="text-app-red-color">*</span>
          </label>
        )}
        <div className="flex justify-between bg-app-white-color mt-1 w-full rounded-md border border-app-gray-300 shadow-sm sm:text-sm text-app-gray-700 h-[38px] p-2">
          -- Loading {label || name} --
          <div className="animate-spin h-5 w-5">
            <IconSpinner />
          </div>
        </div>
      </div>
    );
  }

  if (options && options.length) {
    return (
      <div className="col-span-6 sm:col-span-3 w-full relative">
        {label && (
          <label htmlFor={name} className="block text-sm font-medium text-app-gray-700">
            {label} <span className="text-app-red-color">*</span>
          </label>
        )}
          <select
            name={name}
            id={name}
            className={`${disabled ? 'bg-gray-200' : ''} mt-1 block w-full rounded-md border border-app-gray-300 shadow-sm focus:border-app-gray-300 focus:ring-indigo-500 sm:text-sm pr-16 bg-app-white-color dark:text-primary-text-color`}
            {...register(name, { required: true })}
            disabled={disabled}
            onChange={handleChange}
            value={selectedValue}
          >
            <option value="" disabled>-- Select {label || name} --</option>
            {options.map((option, idx) => (
              <option key={`${name}-${option.value}-${idx}`} value={option.value}>{option.label}</option>
            ))}
          </select>
          {erraseButton && (
            <div className="absolute top-3.5 right-[37px]">
              <span
                className={`${disabled ? 'pointer-events-none' : ''} block text-nowrap text-gray-400 cursor-pointer`}
                onClick={() => {
                  setSelectedValue('');
                  if (clearErrors) {
                    clearErrors(name);
                  }
                }}
              >
                <IconBackSpace />
              </span>
            </div>
          )}
        {errors && (
          <span className="block mt-1 ml-1 text-sm text-app-red-color">{errors?.message}</span>
        )}
      </div>
    );
  }

  return (
    <div className="col-span-6 sm:col-span-3 w-full relative">
      {label && (
        <label htmlFor={name} className="block text-sm font-medium text-app-gray-700">
          {label} <span className="text-app-red-color">*</span>
        </label>
      )}
      <div className="mt-1 w-full rounded-md border border-app-gray-300 shadow-sm sm:text-sm text-app-gray-700 h-[38px] p-2 bg-app-gray-300">
        No data found!
      </div>
    </div>
  );
};
export default SelectField;
