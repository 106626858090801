const TransparentButton = ({ buttonEvent, buttonText, iconButton }) => {
  return (
    <button
      type="button"
      className="inline-flex items-center justify-center 
      rounded-md  border-1 px-2.5 py-1.5 text-sm font-medium 
      text-secondary-color focus:outline-none sm:w-auto  hover:shadow dark:bg-app-white-color dark:text-primary-text-color"
      onClick={buttonEvent}
    >
        {iconButton}
      <div className="ml-2">
      {buttonText}
      </div>
    </button>

  );
};

export default TransparentButton;
