import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import systemsService from '../../logic/systemsService';
import { useNavigate } from 'react-router-dom';
import SAPSystemSchema from './SAPSystemSchema';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';
import OSSystemSchema from './OSSystemSchema';
import BoomiSystemSchema from './BoomiSystemSch';
import { useQuery } from '@tanstack/react-query';

const useAddSystem = (selectedConnectionType) => {
  const schema = selectedConnectionType === 'SAP'
    ? SAPSystemSchema
    : selectedConnectionType === 'OS'
      ? OSSystemSchema
      : BoomiSystemSchema;
  const { register, handleSubmit, formState: { isSubmitted, errors }, clearErrors, reset } = useForm({
    resolver: yupResolver(schema)
  });

  const [envData, setEnvData] = useState([]);
  const [addedCategory, setAddedCategory] = useState('');
  const [testConnectionStatus, setTestConnectionStatus] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [systemIsLoading, setSystemIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [steps, setSteps] = useState([
    { id: '1', name: 'Select Connection', href: '#', status: 'current' },
    { id: '2', name: 'Create Connection', href: '#', status: 'upcoming' },
  ]);
  const [showSlideOver, setShowSlideOver] = useState(false);

  const handleAddCategoryClick = () => {
    setShowSlideOver(!showSlideOver);
  };

  const navigateTo = useNavigate();

  const getAllSystemEnvs = async () => {
    setSystemIsLoading(true);
    setIsLoading(true);
    setError('');

    try {
      const response = await systemsService.getAllEnvs();
      if (response.data) {
        const transformedArray = response.data.map(env => ({
          label: env.name,
          value: env.system_env_id
        }));
        setEnvData(transformedArray);
      } else {
        setEnvData([]);
      }
    } catch (error) {
      console.error('API GET ALL SYSTEMS ENVs ERROR', error);
      setError('An error has occurred !');
    } finally {
      setSystemIsLoading(false);
      setIsLoading(false);
    }
  };
  const {
    isLoading: categoryIsLoading,
    error: categoryError,
    data: categoryData,
    refetch: refetchCategories,
  } = useQuery({
    queryKey: ['getAllCategories'],
    queryFn: async () => {
      const response = await systemsService.getAllCategory();
      const transformedArray = response?.data.map(category => ({
        label: category.name,
        value: category.system_category_id
      }));
      return transformedArray || [];
    },
    onError: () => {
      toast.error("An error has occurred while fetching categories!");
    },
  });

  // eslint-disable-next-line 
  const addSystem = async (data) => {
    setError('');
    setIsLoading(true);

    try {
      const response = await systemsService.addSystem(data);
      if (response.data) {
        navigateTo('/connections');
        toast.success("System has been added successfully");
      }
    } catch (error) {
      console.error('API ERROR :', error);
      setError('An error occurred while adding the system.');
      toast.error('An error occurred while adding the system.');
    } finally {
      setIsLoading(false);
    }
  };

  const addOSSystem = async (data) => {
    setError('');
    setIsLoading(true);

    try {
      const response = await systemsService.addOSSystem(data);
      if (response.data) {
        navigateTo('/cortexFlow/connections');
        toast.success("OS System has been added successfully");
      }
    } catch (error) {
      console.error('API ERROR :', error);
      setError('An error occurred while adding the OS system.');
      toast.error('An error occurred while adding the OS system.');
    } finally {
      setIsLoading(false);
    }
  };

  const addBoomiSystem = async (data) => {
    setError('');
    setIsLoading(true);

    try {
      const response = await systemsService.addBoomiSystem(data);
      if (response.data) {
        navigateTo('/cortexFlow/connections');
        toast.success("Boomi System has been added successfully");
      }
    } catch (error) {
      console.error('API ERROR :', error);
      setError('An error occurred while adding the Boomi system.');
      toast.error('An error occurred while adding the Boomi system.');
    } finally {
      setIsLoading(false);
    }
  };

  // eslint-disable-next-line 
  const testConnection = async (data) => {
    setError('');
    setIsLoading(true);

    try {
      const response = await systemsService.testConnection(data);
      if (response.data?.status_code === 200) {
        toast.success(response.data.detail);
        setTestConnectionStatus(true);
      }
      if (response.data?.status_code === 400) {
        toast.warning(response.data.detail);
      }
    } catch (error) {
      console.error('API ERROR :', error);
      setError('An error occurred while testing the connection.');
      toast.error('An error occurred while testing the connection.');
    } finally {
      setIsLoading(false);
    }
  };

  const saveLocalData = (data) => {
    localStorage.setItem("connectionData", JSON.stringify(data)); 
  }

  const onSubmit = (formData) => {
    const customerId = Cookies.get('customerId');
    // eslint-disable-next-line 
    if (selectedConnectionType === 'SAP') {
      const mappedDataForBackend = {
        sid: formData.sid,
        description: formData.description,
        client: formData.client.toString(),
        lang: formData.language,
        customer_id: customerId,
        system_category_id: formData.category,
        system_env_id: formData.environment,
        connection_type: selectedConnectionType,
        basis_users: [{
          username: formData.basis_user.username,
          password: formData.basis_user.password,
          user_type: "SAP",
        }],
        instances: [{
          instance_number: formData.instances.instance_number.toString(),
          host: formData.instances.host,
          description: formData.instances.description,
        }]
      };
      const testConnectionData = {
        ashost: formData.instances.host,
        sysnr: formData.instances.instance_number.toString(),
        client: formData.client.toString(),
        user: formData.basis_user.username,
        passwd: formData.basis_user.password,
        lang: formData.language
      };
      if (testConnectionStatus) addSystem(mappedDataForBackend);
      else testConnection(testConnectionData);
    }
    if (selectedConnectionType === 'OS') {
      const mappedDataForBackend = {
        name: formData.name,
        description: formData.description,
        host: formData.host,
        port: formData.port,
        user: formData.user,
        password: formData.password,
        provider: formData.provider,
        os_type: formData.os_type
      };
      addOSSystem(mappedDataForBackend);
    }
    if (selectedConnectionType === 'Boomi') {
      const mappedDataForBackend = {
        name: formData.name,
        description: formData.description,
        atom_id: formData.atom_id,
        account_id: formData.account_id,
        email: formData.email,
        password: formData.password,
      };
      addBoomiSystem(mappedDataForBackend);
    }
    
  };

  useEffect(() => {
    getAllSystemEnvs();
  }, []);
  
  // eslint-disable-next-line 
  useEffect(() => { refetchCategories() }, [addedCategory]);

  return {
    isLoading,
    systemIsLoading,
    categoryIsLoading,
    error,
    onSubmit,
    register,
    handleSubmit,
    isSubmitted,
    errors,
    clearErrors,
    navigateTo,
    envData,
    categoryData,
    handleAddCategoryClick,
    showSlideOver,
    setShowSlideOver,
    steps,
    setSteps,
    selectedConnectionType,
    setAddedCategory,
    testConnectionStatus,
    categoryError,
    saveLocalData,
    reset
  };
};

export default useAddSystem;