import { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useService } from './ServiceContext';
import {
  IconClose,
  IconTransaction,
  IconFlow,
  IncidentIcon,
  BusinessIcon,
} from "../../components/_Catalog/icons";
import mino from '../../assets/img/mino.png';
import { useAppGlobal } from '../../core/contexts/AppGlobalProvider';

const ServiceMenu = () => {
  const { showMenu, setShowMenu } = useService();
  const { enbaleBoomiCalendar } = useAppGlobal();
  const menuRef = useRef(null); 

  const handleLinkClick = () => {
    setShowMenu(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setShowMenu(false);
      }
    };

    document.addEventListener('mouseup', handleClickOutside);

    return () => {
      document.removeEventListener('mouseup', handleClickOutside);
    };
  }, [setShowMenu]);

  return (
    <>
<div
 
  ref={menuRef} 
  className={`p-4 inset-0 absolute right-0 z-[999] rounded-lg flex justify-center items-center h-full 
  transition-all bg-slate-50/30 backdrop-blur-md duration-0 dark:bg-slate-800/50 ${showMenu ? 'scale-100 backdrop-blur-3xl' : '!-z-40'}`}
>
        <button
          className="absolute top-4 left-4"
          onClick={handleLinkClick}
        >
          <div className="stroke-inherit stroke-[0.75] min-w-8 w-8 text-primary-text-color">
            <IconClose />
          </div>
        </button>

        <div className="flex flex-col md:flex-row items-center justify-center w-full h-full overflow-auto">
          <div className="md:w-1/3 flex justify-center items-center">
            <img
              src={mino}
              alt=""
              className="max-w-full h-auto"
            />
          </div>

          <div className="hidden md:block h-[50%] w-px bg-gray-300 mx-6"></div>

          <div className="md:w-2/3 p-6">
            <h2 className="text-3xl lg:mt-2 font-semibold text-orange-600 dark:text-gray-200 mb-4 text-center md:text-left">Services</h2>
            <p className="text-gray-500 dark:text-gray-400 mb-6 text-center md:text-left">
              Discover our range of services and select the one that best aligns with your specific needs.
            </p>

            <div className="grid grid-cols-1 2xl:grid-cols-2 gap-3 ">
  <Link
    to="/tasks"
    className="flex items-center bg-secondary-bg-color dark:bg-gray-700 shadow-lg rounded-lg p-4 duration-300 hover:-translate-y-1 hover:shadow-2xl"
    onClick={() => handleLinkClick('HyperAutomation')}
  >
    <div className="mr-4 dark:text-gray-200">
      <IconTransaction className="w-8 h-8" color={"#3D57DE"} />
    </div>
    <div>
      <h3 className="text-xl font-bold text-primary-color dark:text-gray-200 mb-2">SAP Hyper Monitoring</h3>
      <p className="text-gray-600 dark:text-primary-text-color text-justify">
      Automate repetitive tasks using no-code workflows, empowering efficient SAP task management and enhancing data-driven decision-making processes.
      </p>
    </div>
  </Link>

  <Link
    to="/operation-flow"
    className="flex items-center bg-secondary-bg-color dark:bg-gray-700 shadow-lg rounded-lg p-4 duration-300 hover:-translate-y-1 hover:shadow-2xl"
    onClick={() => handleLinkClick('CortexFlow')}
  >
    <div className="mr-4 dark:text-gray-200">
      <IconFlow className="w-8 h-8" color={"#3D57DE"} />
    </div>
    <div>
      <h3 className="text-xl font-bold text-primary-color dark:text-gray-200 mb-2">CortexFlow Director</h3>
      <p className="text-gray-600 dark:text-primary-text-color text-justify">
      Design automated workflows with Cortex Flow’s drag-and-drop tool, powered by Sapient AI, to optimize operations, streamline processes, and boost productivity.</p>
    </div>
  </Link>

  <Link
    to="/incident/dashboard"
    className="flex items-center bg-secondary-bg-color dark:bg-gray-700 shadow-lg rounded-lg p-4 duration-300 hover:-translate-y-1 hover:shadow-2xl"
    onClick={() => handleLinkClick('Incident Hub')}
  >
    <div className="mr-4 dark:text-gray-200">
      <IncidentIcon className="w-8 h-8" color={"#3D57DE"} />
    </div>
    <div>
      <h3 className="text-xl font-bold text-primary-color dark:text-gray-200 mb-2">Incident Hub</h3>
      <p className="text-gray-600 dark:text-primary-text-color text-justify">
      Manage incidents centrally with the Incident Hub’s dashboard, escalation settings, and notification center, improving response processes and operational efficiency. </p>
    </div>
  </Link>

  <a
    href="/avaxia-business-view"
    className="flex items-center bg-secondary-bg-color dark:bg-gray-700 shadow-lg rounded-lg p-4 duration-300 hover:-translate-y-1 hover:shadow-2xl"
    onClick={() => handleLinkClick('Avaxia Business View')}
  >
    <div className="mr-4 dark:text-gray-200">
      <BusinessIcon className="w-8 h-8" color={"#3D57DE"} />
    </div>
    <div>
      <h3 className="text-xl font-bold text-primary-color dark:text-gray-200 mb-2">Avaxia Business View</h3>
      <p className="text-gray-600 dark:text-primary-text-color text-justify">
      View real-time system status using AI insights from SAP Hyper Monitoring, with KPI statistics and the Sapient chatbot for natural language queries and real-time information.
      </p>
    </div>
  </a>

  {enbaleBoomiCalendar && (<Link
    to="/calendar/environments"
    className="flex items-center bg-secondary-bg-color dark:bg-gray-700 shadow-lg rounded-lg p-4 duration-300 hover:-translate-y-1 hover:shadow-2xl"
    onClick={() => handleLinkClick('Boomi Calendar')}
  >
    <div className="mr-4 dark:text-gray-200">
      <IncidentIcon className="w-8 h-8" color={"#3D57DE"} />
    </div>
    <div>
      <h3 className="text-xl font-bold text-primary-color dark:text-gray-200 mb-2">Boomi Calendar</h3>
      <p className="text-gray-600 dark:text-primary-text-color text-justify">
      Manage incidents centrally with the Incident Hub’s dashboard, escalation settings, and notification center, improving response processes and operational efficiency. </p>
    </div>
  </Link>)}


</div>

          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceMenu;