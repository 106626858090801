import PageLayout from "../../../components/PageLayout/PageLayout";
import UpdateBoomiSystemForm from "./UpdateBoomiSystemForm/UpdateBoomiSystemForm";
import useUpdateBoomiSystem from "./logic/useUpdateBoomiSystem";

const UpdateBoomiCortexFlowSystem = () => {
  const { isLoading, error, onSubmit, register, handleSubmit, isSubmitted, errors, data } = useUpdateBoomiSystem();

  return (
    <PageLayout pageName="Update Connections" pageClass="updateBoomiSystemPage" redirectBack='/cortexFlow/connections'>
      <UpdateBoomiSystemForm 
        isLoading={isLoading}
        error={error}
        onSubmit={onSubmit}
        register={register}
        handleSubmit={handleSubmit}
        isSubmitted={isSubmitted}
        errors={errors}
        data={data}
      />
    </PageLayout>
  );
};

export default UpdateBoomiCortexFlowSystem;