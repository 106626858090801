import jwtSign from "jwt-encode";
import Cookies from 'js-cookie';

export const userHasPermission = (user, permission, permissionsField = 'permissions') => {
  if(!user || !user[permissionsField] || !user[permissionsField].includes(permission)) return false;
  return true;
};

export const applyInterceptors = (axiosInstance, options = {}) => {
  const { tokenPrefix = "", tokenHeader = "", tokenType = "" } = options;

  // Adding token to headers
  axiosInstance.interceptors.request.use((config) => {
    const accessToken = Cookies.get(`${tokenPrefix}_act`);
    if (accessToken && tokenHeader) {
      config.headers[tokenHeader] = tokenType ? `${tokenType} ${accessToken}` : accessToken;
    } 
    return config;
  });

  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      const networkError = error?.code === 'ERR_NETWORK';
      const statusCode = error.response?.status;
      const tokenExpired = error.response?.data?.detail === 'Token has expired';

      if (networkError) {
        return Promise.reject('A network error has occurred!');
      }

      if (tokenExpired) {
        window.location.replace('/logout');
        return;
      }

      if ([401, 403].includes(statusCode)) {
        const errorCode = error.response?.data?.code;
        const logoutErrors = [6001, 6003, 6004, 6009, 6010];
        const forbiddenErrors = [6002, 6005];

        if (logoutErrors.includes(errorCode)) {
          window.location.replace('/logout');
        } else if (forbiddenErrors.includes(errorCode)) {
          window.location.replace('/403');
        }
      }

      return Promise.reject(error);
    }
  );
};

//?NOTE: THIS IS A TEMPORARY SOLUTION UNTIL BACKEND MIDDLEWARES ARE IMPLEMENTED
export const tokensGenerator = async () => {
  try {
    let signInObject = { username: "token", password: "token" };
    const currentTime = Math.floor(Date.now() / 1000);
    const expirationTime = currentTime + 4 * 60 * 60;
    signInObject.exp = expirationTime;

    const accessTokenDipsatcher = jwtSign(
      signInObject,
      process.env.REACT_APP_AUTH_GEN_KEY_DISPATCHER,
      { expiresIn: expirationTime }
    );
    const accessTokenExtractor = jwtSign(
      signInObject,
      process.env.REACT_APP_AUTH_GEN_KEY_EXTRACTOR,
      { expiresIn: expirationTime }
    );
    const accessTokenDecision = jwtSign(
      signInObject,
      process.env.REACT_APP_AUTH_GEN_KEY_DECISION,
      { expiresIn: expirationTime }
    );
    const accessTokenSapient = jwtSign(
      signInObject,
      process.env.REACT_APP_AUTH_GEN_KEY_SAPIENT,
      { expiresIn: expirationTime }
    );
    // Set cookies (simulate HTTP-only by not exposing to JavaScript)
    Cookies.set("customerId", process.env.REACT_APP_CUSTOMER_ID, {
      secure: true,
      sameSite: "Strict",
    });
    return {
      accessTokenDipsatcher,
      accessTokenExtractor,
      accessTokenDecision,
      accessTokenSapient
    };
  } catch (error) {
    console.error(error);
    throw error;
  }
};