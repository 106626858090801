import React, { useRef,useState } from "react";
import PrimaryButton from "../../../../components/Buttons/PrimaryButton";
import TransparentButton from "../../../../components/Buttons/TransparentButton";
import { IconAdd, IconCheck, IconSpinner, IconTestCnx, IconX } from "../../../../components/_Catalog/icons";
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import InputField from "../../../../components/AppInputForm/InputField/InputField";
import SelectField from "../../../../components/AppInputForm/SelectField/SelectField";
import AddCategory from "../AddCategory/AddCategory";

const SapSystemForm = ({
  envData,
  categoryData,
  onSubmit,
  register,
  handleSubmit,
  isLoading,
  systemIsLoading,
  categoryIsLoading, 
  error,
  isSubmitted,
  errors,
  clearErrors,
  handleAddCategoryClick,
  navigateTo,
  showSlideOver,
  setShowSlideOver,
  disabled,
  selectedConnectionType,
  setSelectedConnectionType,
  setAddedCategory,
  testConnectionStatus,
  categoryError,
}) => {
  const formRef = useRef(null);
  const [showPassword, setShowPassword] = useState(false);


  const handleTypeChange = (e) => {
    setSelectedConnectionType(e.target.value);
  };
  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };


  return (
    <div className="md:col-span-2 md:mt-0">
      <form ref={formRef} onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <div className="overflow-x-auto max-h-[74vh] rounded-lg border sm:rounded-md custom-scrollbar">
          <div className="sticky bg-[#FFFFFF] dark:bg-secondary-bg-color z-10 top-0">
            <label htmlFor="type" className="block ml-6 pt-4 text-sm font-medium text-app-gray-700">
              Connection type
            </label>
            <div className="flex justify-between mx-6 pb-3 border-b">
              <div>
                <select
                  className="w-24 mt-1 block rounded-md border border-app-gray-300 shadow-sm focus:border-app-gray-300 
                  focus:ring-indigo-500 sm:text-sm text-app-gray-700 pr-2 "
                  value={selectedConnectionType}
                  onChange={handleTypeChange}
                >
                  <option value="SAP">SAP</option>
                  <option value="OS">OS</option>
                  <option value="Boomi">Boomi</option>

                </select>
              </div>
              <TransparentButton buttonEvent={handleAddCategoryClick} buttonText="Add Category" iconButton={<IconAdd color="var(--primary-text-color)" />} />
            </div>
          </div>

          <div className="px-4 py-3 sm:p-6 bg-transparent-dark">
            <div className="pt-6 px-6 mb-3 border rounded-lg">
              <span className="mb-4 block text-xl text-app-gray-700">Connection Details</span>
              <div className="grid grid-cols-6 gap-6 mt-2 mb-6">
                <InputField
                  name="description"
                  label="Connection name"
                  placeholder="Enter description"
                  disabled={disabled}
                  errors={isSubmitted && errors.description}
                  register={register}
                />
                <InputField
                  name="sid"
                  label="SID"
                  placeholder="Enter SID"
                  disabled={disabled}
                  errors={isSubmitted && errors.sid}
                  register={register}
                />
                <SelectField
                  name="environment"
                  label="Environment"
                  options={envData}
                  disabled={disabled}
                  errors={isSubmitted && errors.environment}
                  clearErrors={clearErrors}
                  register={register}
                  isLoading={systemIsLoading}
                />
                <SelectField
                  name="category"
                  label="Category"
                  options={categoryData}
                  disabled={disabled}
                  error={categoryError}
                  errors={isSubmitted && errors.category}
                  clearErrors={clearErrors}
                  register={register}
                  isLoading={categoryIsLoading}
                />
                <InputField
                  name="client"
                  label="Client"
                  type="number"
                  placeholder="Enter client"
                  disabled={disabled}
                  errors={isSubmitted && errors.client}
                  register={register}
                />
                <SelectField
                  name="language"
                  label="Language"
                  options={[
                    { value: "en", label: "English" },
                    { value: "jp", label: "Japanese" }
                  ]}
                  disabled={testConnectionStatus}
                  errors={isSubmitted && errors.language}
                  clearErrors={clearErrors}
                  register={register}
                />
              </div>
            </div>

            <div className="pt-6 px-6 mb-3 border rounded-lg">
              <span className="mb-4 block text-xl text-app-gray-700">Instance details</span>
              <div className="grid grid-cols-6 gap-6 mt-2 mb-6">
                <InputField
                  name="instances.instance_number"
                  label="Instance Number"
                  type="number"
                  placeholder="Enter instance number"
                  disabled={testConnectionStatus}
                  errors={isSubmitted && errors.instances?.instance_number}
                  register={register}
                />
                <InputField
                  name="instances.host"
                  label="Host"
                  placeholder="Enter host"
                  type="text"
                  disabled={testConnectionStatus}
                  errors={isSubmitted && errors.instances?.host}
                  register={register}
                />
                <InputField
                  name="instances.description"
                  label="Instance Description"
                  placeholder="Enter instance description"
                  errors={isSubmitted && errors.instances?.description}
                  register={register}
                />
              </div>
            </div>

            <div className="pt-6 px-6 border rounded-lg">
              <span className="mb-4 block text-xl text-app-gray-700">User credentials</span>
              <div className="grid grid-cols-6 gap-6 mt-2 mb-6">
                <InputField
                  name="basis_user.username"
                  label="User"
                  placeholder="Enter User"
                  disabled={testConnectionStatus}
                  errors={isSubmitted && errors.basis_user?.username}
                  register={register}
                />
                <div className="relative col-span-3">
                <InputField
                  name="basis_user.password"
                  label="Password"
                  type={showPassword ? "text" : "password"}
                  placeholder="Enter password"
                  disabled={testConnectionStatus}
                  errors={isSubmitted && errors.basis_user?.password}
                  register={register}
                />
                <span
                    className="absolute inset-y-0 right-2 top-9 cursor-pointer"
                    onClick={togglePasswordVisibility}
                  >
                    {showPassword ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
                  </span>
               </div>
              </div>
            </div>
          </div>

          <div className="flex sticky bottom-0 justify-between gap-3 bg-[#F7F7F7] dark:bg-[#1f3047] px-4 py-3 sm:px-6">
            <div className="flex justify-between w-full">
              <TransparentButton buttonEvent={() => navigateTo('/cortexFlow/connections')} buttonText="Cancel" iconButton={<IconX sm color="var(--secondary-color)" />} />
              <div className="flex gap-4 overflow-hidden group-hover:block">
               <PrimaryButton type="submit" isLoading={isLoading} buttonText="Check Credentials" disabled={false} iconButton={<IconTestCnx />} />
                <div
                  className={
                    "group"}
                >
                  <button
                    type={"submit"}
                    className={`${!testConnectionStatus ? 'cursor-not-allowed bg-green-900' : 'bg-green-700'} inline-flex items-center justify-center rounded-md border border-transparent 
                    px-3 py-1.5 text-sm font-medium text-app-white-color dark:text-primary-text-color hover:shadow focus:outline-none min-w-40 ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
                    disabled={!testConnectionStatus}
                  >
                    {isLoading ? (
                      <div className="animate-spin h-5 w-5 mr-2">
                        <IconSpinner />
                      </div>
                    ) : (
                      <div className='mr-2'>
                          {<IconCheck />}
                      </div>
                    )} Submit
                  </button>
                  <div className="absolute bottom-[60px] right-6 w-full text-wrap mt-2 hidden max-w-xs p-2 text-xs text-primary-text-color border bg-[#fff] dark:bg-primary-color rounded-xl shadow-lg z-50 group-hover:block">
                    Please check your credentials first
                  </div>
                </div>
              </div>
             
            </div>
          </div>
        </div>
      </form>
      {showSlideOver && <AddCategory showSlideOver={showSlideOver} setShowSlideOver={setShowSlideOver} setAddedCategory={setAddedCategory} />}
    </div>
  );
};

export default SapSystemForm;