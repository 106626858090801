import { useParams } from "react-router-dom";
import { useQuery } from '@tanstack/react-query';
import systemDetailsService from "./systemDetailsService"; // Ensure this service has the Boomi method
import { toast } from "react-toastify";
import { useAppGlobal } from "../../../../core/contexts/AppGlobalProvider";

const useSystemDetails = () => {
  const { id } = useParams();
  const { selectedSystem, setSelectedSystem } = useAppGlobal();

  const { data, isLoading, error } = useQuery({
    queryKey: ['systemDetails', id, selectedSystem],
    queryFn: () => {
      if (selectedSystem === 'SAP') {
        return systemDetailsService.getSystemById(id);
      } else if (selectedSystem === 'OS') {
        return systemDetailsService.getOSSystemById(id);
      } else if (selectedSystem === 'Boomi') {
        return systemDetailsService.getBoomiSystemById(id);
      } else {
        throw new Error('Unsupported system type');
      }
    },
    onError: (error) => {
      toast.error('An error has occurred');
      console.error('API GET SYSTEM ERROR', error);
    },
    select: (data) => data.data || null,
    enabled: !!selectedSystem && !!id,
  });

  return {
    data,
    isLoading,
    error,
    selectedSystem,
    setSelectedSystem
  };
};

export default useSystemDetails;
