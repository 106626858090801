import { IconSpinner } from "../../_Catalog/icons";

const InputField = ({ name, isLoading, label, placeholder, register, required, error, errors, disabled, defaultValue, value, ...props }) => {
  /* const errorMessage = errors?.[name]?.message || error; */

  /* if (errorMessage) {
    return (
      <div className="col-span-6 sm:col-span-3 w-full relative">
        {label && (
          <label htmlFor={name} className="block text-sm font-medium text-app-gray-700">
            {label} <span className="text-app-red-color">*</span>
          </label>
        )}
        <div className="mt-1 w-full rounded-md border border-app-gray-300 shadow-sm sm:text-sm text-app-gray-700 h-[38px] p-2 bg-red-400">
          {errorMessage}
        </div>
      </div>
    );
  } */

  if (isLoading) {
    return (
      <div className="col-span-6 sm:col-span-3 w-full relative">
        {label && (
          <label htmlFor={name} className="block text-sm font-medium text-app-gray-700">
            {label} <span className="text-app-red-color">*</span>
          </label>
        )}
        <div className="flex justify-between bg-app-white-color mt-1 w-full rounded-md border border-app-gray-300 shadow-sm sm:text-sm text-app-gray-700 h-[38px] p-2">
          -- Loading {label || name} --
          <div className="animate-spin h-5 w-5">
            <IconSpinner />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="col-span-6 sm:col-span-3">
      <label htmlFor={name} className="block text-sm font-medium text-app-gray-700">
        {label} <span className="text-app-red-color">*</span>
      </label>
      <input
        autoComplete="off"
        {...props}
        defaultValue={defaultValue}
        className={`${disabled ? "bg-gray-200 dark:bg-secondary-color" : 'bg-app-white-color'} text-primary-text-color mt-1 block w-full rounded-md border-app-gray-300 shadow-sm
         focus:border-primary-color sm:text-sm`}
        placeholder={placeholder}
        {...register(name, { required: required })}
        disabled={disabled}
      />
      {errors && <span className="block mt-1 ml-1 text-sm text-app-red-color">{errors?.message}</span>}
    </div>
  );
};

export default InputField;
