import { IconCheck } from '../../../../components/_Catalog/icons';

const Panel = ({ steps, onStepClick }) => {
  return (
    <nav aria-label="Progress" className="mb-3">
      <ol className="divide-y divide-gray-300 rounded-xl border border-app-gray-300 md:flex md:divide-y-0 dark:bg-secondary-bg-color">
        {steps?.map((step, stepIdx) => (
          <li key={step.id} className="relative md:flex md:flex-1">
            {step.status === 'complete' ? (
              <button
                onClick={() => onStepClick(step.id)}
                className="group flex w-full items-center"
              >
                <span className="flex items-center px-6 py-3 text-sm font-medium">
                  <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-primary-color group-hover:bg-indigo-800">
                    <IconCheck
                      className="h-6 w-6"
                      color="white"
                      aria-hidden="true"
                    />
                  </span>
                  <span className="ml-4 text-sm font-medium text-gray-900 dark:text-app-gray-700">
                    {step.name}
                  </span>
                </span>
              </button>
            ) : step.status === 'current' ? (
              <button
                onClick={() => onStepClick(step.id)}
                className="flex items-center px-6 py-3 text-sm font-medium"
                aria-current="step"
              >
                <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-primary-color">
                    <span className="text-primary-color dark:text-orange-600">{step.id}</span>
                </span>
                  <span className="ml-4 text-sm font-medium text-primary-color dark:text-orange-600">
                  {step.name}
                </span>
              </button>
            ) : (
              <button
                onClick={() => onStepClick(step.id)}
                className="group flex items-center"
              >
                <span className="flex items-center px-6 py-3 text-sm font-medium">
                  <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-app-gray-300 group-hover:border-gray-400">
                    <span className="text-gray-500 dark:text-primary-text-color group-hover:text-gray-900">
                      {step.id}
                    </span>
                  </span>
                      <span className="ml-4 text-sm font-medium text-gray-500 dark:text-primary-text-color group-hover:text-gray-900">
                    {step.name}
                  </span>
                </span>
              </button>
            )}

            {stepIdx !== steps.length - 1 ? (
              <div
                className="absolute top-0 right-0 hidden h-full w-5 md:block"
                aria-hidden="true"
              >
                <svg
                  className="h-full w-full text-[#E0E0E1]"
                  viewBox="0 0 22 80"
                  fill="none"
                  preserveAspectRatio="none"
                >
                  <path
                    d="M0 -2L20 40L0 82"
                    vectorEffect="non-scaling-stroke"
                    stroke="currentcolor"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            ) : null}
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default Panel;
