import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import systemsService from '../../logic/systemsService';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import UpdateOSSystemSchema from './UpdateOSSystemSchema';

const useUpdateOSSystem = () => {
  const { register, handleSubmit, formState: { isSubmitted, errors }, reset } = useForm({ resolver: yupResolver(UpdateOSSystemSchema) });

  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const { id } = useParams();

  const navigateTo = useNavigate();

  const getOSSystemById = async () => {
    setIsLoading(true);
    setError('');

    try {
      const response = await systemsService.getOSSystemById(id);
      if (response.data) {
        setData(response.data);
        reset(response.data);
      } else {
        setData();
      }
    } catch (error) {
      console.error('API GET SYSTEM BY ID ERROR', error);
      setError('An error has occurred !');
    } finally {
      setIsLoading(false);
    }
  };


  const updateOSSystem = async (updatedConnectionDetails) => {
    setIsLoading(true);

    try {
      const systemDetails = await systemsService.updateOSSystem(id, updatedConnectionDetails);

      if (systemDetails.data) {
        navigateTo('/connections');
        toast.success("OS System has been updated successfully");
      }
    } catch (error) {
      console.error('API ERROR :', error);
      toast.error('An error occurred while updating the OS system.');
    } finally {
      setIsLoading(false);
    }
  };

  const onSubmit = (formData) => {
    const updatedConnectionDetails = {
      name: formData.name,               
      description: formData.description,  
      provider: formData.provider,        
      os_type: formData.os_type,          
      host: formData.host,                
      port: formData.port.toString(),     
      user: formData.user,               
      password: formData.password,   
    };

    updateOSSystem(updatedConnectionDetails);
  };

  useEffect(() => {
    getOSSystemById(id);
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps


  return {
    isLoading,
    error,
    onSubmit,
    register,
    handleSubmit,
    isSubmitted,
    errors,
    navigateTo,
    data
  };
};

export default useUpdateOSSystem;
