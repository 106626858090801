import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import systemsService from '../../logic/systemsService';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';
import UpdateSAPSystemSchema from './UpdateSAPSystemSchema';
import { useQuery } from '@tanstack/react-query';

const useUpdateSystem = () => {
  const { register, handleSubmit, formState: { isSubmitted, errors }, reset } = useForm({ resolver: yupResolver(UpdateSAPSystemSchema) });

  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [addedCategory, setAddedCategory] = useState('');

  const { id } = useParams();
  const [showSlideOver, setShowSlideOver] = useState(false);

  const handleAddCategoryClick = () => {
    setShowSlideOver(!showSlideOver);
  };

  const navigateTo = useNavigate();

  const getSystemById = async () => {
    setIsLoading(true);
    setError('');

    try {
      const response = await systemsService.getSystemById(id);
      if (response.data) {
        setData(response.data);
        reset(response.data);
      } else {
        setData();
      }
    } catch (error) {
      console.error('API GET SYSTEM BY ID ERROR', error);
      setError('An error has occurred !');
    } finally {
      setIsLoading(false);
    }
  };

  const {
    isLoading: categoryIsLoading,
    error: categoryError,
    data: categoryData,
    refetch: refetchCategories,
  } = useQuery({
    queryKey: ['getAllCategories'],
    queryFn: async () => {
      const response = await systemsService.getAllCategory();
      const transformedArray = response?.data.map(category => ({
        label: category.name,
        value: category.system_category_id
      }));
      return transformedArray || [];
    },
    onError: () => {
      toast.error("An error has occurred while fetching categories!");
    },
  });

  const updateSystem = async (updatedConnectionDetails, updatedInstanceDetails, updatedUserCredentials) => {
    setIsLoading(true);

    try {
      const [systemDetails, systemInstance, systemCredentials] = await Promise.all([
        systemsService.updateInstance(updatedInstanceDetails),
        systemsService.updateSystem(id, updatedConnectionDetails),
        systemsService.updateUserCredentials(updatedUserCredentials),
      ]);
      if (systemDetails.data && systemInstance.data && systemCredentials.data) {
        navigateTo('/connections');
        toast.success("System has been updated successfully");
      }
    } catch (error) {
      console.error('API ERROR :', error);
      toast.error('An error occurred while updating the system.');
    } finally {
      setIsLoading(false);
    }
  };

  const onSubmit = (formData) => {
    const customerId = Cookies.get('customerId');
    const updatedConnectionDetails = {
      sid: formData.sid,
      description: formData.description,
      lang: formData.language,
      client: formData.client.toString(),
      customer_id: customerId,
      system_category_id: formData.system_category_id,
      system_env_id: formData.system_env_id,
      connection_type: 'SAP',
    };
    const updatedInstanceDetails = {
      instance_number: formData.instanceNumber,
      description: formData.instanceDescription,
      host: formData.instanceHost,
      system_id: id,
      instance_id: formData.instanceId
    };
    const updatedUserCredentials = {
      username: formData.username,
      password: formData.password,
      user_type: "SAP",
      system_id: id,
      basis_user_id: formData.basisUserId
    };
    updateSystem(updatedConnectionDetails, updatedInstanceDetails, updatedUserCredentials);
  };

  useEffect(() => {
    getSystemById(id);
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  // eslint-disable-next-line 
  useEffect(() => { refetchCategories() }, [addedCategory]);

  return {
    isLoading,
    error,
    onSubmit,
    register,
    handleSubmit,
    isSubmitted,
    errors,
    navigateTo,
    data,
    handleAddCategoryClick,
    showSlideOver,
    setAddedCategory,
    categoryData,
    categoryError,
    categoryIsLoading,
    setShowSlideOver
  };
};

export default useUpdateSystem;
