import { createContext, useCallback, useEffect, useState } from "react";
import useCookie from "../hooks/useCookie";
import {
  BrowserAuthError,
  InteractionRequiredAuthError,
  PublicClientApplication,
} from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import useLocalStorage from "../hooks/useLocalStorage";
import { msalConfig } from "../utils/apis/httpMsal";
import { useNavigate } from "react-router-dom";
import { tokensGenerator } from "../utils/helpers";
import authService from "../services/authService";

export const AppAuthContext = createContext();

const AppAuthProvider = ({ children }) => {
  const [dispatcherToken, setDispatcherToken] = useCookie(
    `${process.env.REACT_APP_DISPATCHER_PREFIX}_act`
  );
  const [extractorToken, setExtractorToken] = useCookie(
    `${process.env.REACT_APP_EXTRACTOR_PREFIX}_act`
  );
  const [decisionToken, setDecisionToken] = useCookie(
    `${process.env.REACT_APP_DECISION_PREFIX}_act`
  );
  const [sapientToken, setSapientToken] = useCookie(
    `${process.env.REACT_APP_SAPIENT_PREFIX}_act`
  );
  const [localSession, setLocalSession] = useCookie("x-ls");
  const [userToken, setUserToken] = useLocalStorage("mca-x-act");
  const [refreshToken, setRefreshToken] = useLocalStorage("mca-x-rft");
  const [authenticatedUser, setAuthenticatedUser] = useState();
  const [authenticatedUserLoading, setAuthenticatedUserLoading] =
    useState(true);
  const [authenticatedUserError, setAuthenticatedUserError] = useState();
  const msalInstance = new PublicClientApplication(msalConfig);
  msalInstance.initialize();
  const microsoftAzureAccounts = msalInstance.getAllAccounts();
  const navigate = useNavigate();
  const silentLogin = useCallback(async () => {
    try {
      setAuthenticatedUserLoading(true);
      if (!msalInstance[0] && !localSession && !userToken) {
        setDecisionToken();
        setExtractorToken();
        setDispatcherToken();
        setSapientToken();
        setAuthenticatedUser();
        return;
      }

      const silentRequest = {
        account: microsoftAzureAccounts[0],
      };
      const loginResponse = await msalInstance.acquireTokenSilent(
        silentRequest
      );
      if (loginResponse) {
        const {
          accessTokenDipsatcher,
          accessTokenExtractor,
          accessTokenDecision,
          accessTokenSapient,
        } = await tokensGenerator();
        if (
          !accessTokenDipsatcher ||
          !accessTokenExtractor ||
          !accessTokenDecision ||
          !accessTokenSapient
        )
          throw new Error(7004);
        setDispatcherToken(accessTokenDipsatcher);
        setExtractorToken(accessTokenExtractor);
        setDecisionToken(accessTokenDecision);
        setSapientToken(accessTokenSapient);
        setAuthenticatedUser(loginResponse.account);
      }
    } catch (err) {
      if (err instanceof InteractionRequiredAuthError) {
        navigate("/logout", { replace: true });
      } else {
        if (err instanceof BrowserAuthError) {
        }
      }
    } finally {
      setAuthenticatedUserLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    localSession,
    microsoftAzureAccounts,
    navigate,
    setAuthenticatedUser,
    setDecisionToken,
    setDispatcherToken,
    setExtractorToken,
    setSapientToken,
    userToken,
  ]);
  const getUserData = useCallback(async () => {
    setAuthenticatedUserLoading(true);
    try {
      const response = await authService.getUserData();
      if (response.data) {
        setAuthenticatedUser(response.data);
      }
    } catch (error) {
      console.log("API ERROR :", error);
    } finally {
      setAuthenticatedUserLoading(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setAuthenticatedUser]);
  useEffect(() => {
    silentLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const providerValue = {
    dispatcherToken,
    extractorToken,
    decisionToken,
    sapientToken,
    setDispatcherToken,
    setDecisionToken,
    setExtractorToken,
    setSapientToken,
    userToken,
    setUserToken,
    refreshToken,
    setRefreshToken,
    authenticatedUser,
    setAuthenticatedUser,
    authenticatedUserLoading,
    setAuthenticatedUserLoading,
    authenticatedUserError,
    setAuthenticatedUserError,
    localSession,
    setLocalSession,
    getUserData,
  };

  return (
    <AppAuthContext.Provider value={providerValue}>
      <MsalProvider instance={msalInstance}>{children}</MsalProvider>
    </AppAuthContext.Provider>
  );
};

export default AppAuthProvider;
