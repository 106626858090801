import httpExtractor from "../../../core/utils/apis/httpExtractor";
import httpMain from "../../../core/utils/apis/httpMain";

const getAllSystems = async (page, limit) => {
  try {
    const url = page && limit ? `/systems/?skip=${(page - 1) * limit}&limit=${limit}` : '/systems/';

    return await httpMain({
      method: 'GET',
      url
    });
  } catch (e) {
    throw e;
  }
};

const getAllOSSystems = async () => {
  try {
    const url = '/os_connection/';

    return await httpMain({
      method: 'GET',
      url
    });
  } catch (e) {
    throw e;
  }
};

const searchSystems = async (searchTerm, page, limit) => {
  try {
    const url = `/systems/search_systems?search=${searchTerm}&skip=${(page - 1) * limit}&limit=${limit}`;

    return await httpMain({
      method: 'GET',
      url
    });
  } catch (e) {
    throw e;
  }
};

const getAllInstances = async () => {
  try {
    const response = await httpMain({
      method: 'GET',
      url: '/instances/'
    });

    return response;
  } catch (e) {
    throw e;
  }
};

const getAllCategory = async () => {
  try {
    const response = await httpMain({
      method: 'GET',
      url: '/categories/'
    });

    return response;
  } catch (e) {
    throw e;
  }
};

const getAllEnvs = async () => {
  try {
    const response = await httpMain({
      method: 'GET',
      url: '/environments/'
    });

    return response;
  } catch (e) {
    throw e;
  }
};

const addSystem = async (reqBody) => {
  try {
    const response = await httpMain({
      method: 'POST',
      url: '/systems/',
      data: reqBody
    });

    return response;
  } catch (e) {
    throw e;
  }
};

const getSystemById = async (systemId) => {
  try {
    const response = await httpMain({
      method: 'GET',
      url: `/systems/${systemId}`
    });

    return response;
  } catch (e) {
    throw e;
  }
};

const addSystemCategory = async (reqBody) => {
  try {
    const response = await httpMain({
      method: 'POST',
      url: `/categories/`,
      data: reqBody
    });

    return response;
  } catch (e) {
    throw e;
  }
};

const addInstance = async (reqBody) => {
  try {
    const response = await httpMain({
      method: 'POST',
      url: `/instances/`,
      data: reqBody
    });

    return response;
  } catch (e) {
    throw e;
  }
};

const updateSystem = async (idSystem, reqBody) => {
  try {
    const response = await httpMain({
      method: 'PUT',
      url: `/systems/${idSystem}`,
      data: reqBody
    });

    return response;
  } catch (e) {
    throw e;
  }
};

const updateInstance = async (reqBody) => {
  try {
    const response = await httpMain({
      method: 'PUT',
      url: `/instances/${reqBody.instance_id}`,
      data: reqBody
    });

    return response;
  } catch (e) {
    throw e;
  }
};

const updateUserCredentials = async (reqBody) => {
  try {
    const response = await httpMain({
      method: 'PUT',
      url: `/basis_users/${reqBody.basis_user_id}`,
      data: reqBody
    });

    return response;
  } catch (e) {
    throw e;
  }
};

const testConnection = async (reqBody) => {
  try {
    const response = await httpExtractor({
      method: 'POST',
      url: `/test_connection`,
      data: reqBody
    });

    return response;
  } catch (e) {
    throw e;
  }
};

const addOSSystem = async (reqBody) => {
  try {
    const response = await httpMain({
      method: 'POST',
      url: `/os_connection/`,
      data: reqBody
    });

    return response;
  } catch (e) {
    throw e;
  }
};

const enableSystem = async (id) => {
  try {
    const response = await httpMain({
      method: 'POST',
      url: `/task_transactions/enable_system/${id}`,
    });

    return response;
  } catch (e) {
    throw e;
  }
};

const disableSystem = async (id) => {
  try {
    const response = await httpMain({
      method: 'POST',
      url: `/task_transactions/disable_system/${id}`,
    });

    return response;
  } catch (e) {
    throw e;
  }
};

const deleteSystemById = async (id) => {
  try {
    const response = await httpMain({
      method: 'DELETE',
      url: `/systems/${id}`,
    });

    return response;
  } catch (e) {
    throw e;
  }
};

const getAllBoomiSystems = async () => {
  try {
    const url = '/boomi_connection/';

    return await httpMain({
      method: 'GET',
      url
    });
  } catch (e) {
    throw e;
  }
};


const addBoomiSystem = async (reqBody) => {
  try {
    const response = await httpMain({
      method: 'POST',
      url: `/boomi_connection/`,
      data: reqBody
    });

    return response;
  } catch (e) {
    throw e;
  }
};

const updateOSSystem = async (idSystem, reqBody) => {
  try {
    const response = await httpMain({
      method: 'PUT',
      url: `/os_connection/${idSystem}`,
      data: reqBody
    });

    return response;
  } catch (e) {
    throw e;
  }
};

const updateBoomiSystem = async (idSystem, reqBody) => {
  try {
    const response = await httpMain({
      method: 'PUT',
      url: `/boomi_connection/${idSystem}`,
      data: reqBody
    });

    return response;
  } catch (e) {
    throw e;
  }
};

const deleteOSSystemById = async (id) => {
  try {
    const response = await httpMain({
      method: 'DELETE',
      url: `/os_connection/${id}`,
    });

    return response;
  } catch (e) {
    throw e;
  }
};

const deleteBoomiSystemById = async (id) => {
  try {
    const response = await httpMain({
      method: 'DELETE',
      url: `/boomi_connection/${id}`,
    });

    return response;
  } catch (e) {
    throw e;
  }
};

const getOSSystemById = async (systemId) => {
  try {
    const response = await httpMain({
      method: 'GET',
      url: `/os_connection/id/${systemId}`
    });

    return response;
  } catch (e) {
    throw e;
  }
};

const getBoomiSystemById = async (systemId) => {
  try {
    const response = await httpMain({
      method: 'GET',
      url: `/boomi_connection/${systemId}`
    });

    return response;
  } catch (e) {
    throw e;
  }
};



const systemsService = {
  getAllSystems,
  searchSystems,
  getAllInstances,
  getSystemById,
  addSystem,
  getAllCategory,
  getAllEnvs,
  addSystemCategory,
  addInstance,
  testConnection,
  addOSSystem,
  updateInstance,
  updateUserCredentials,
  deleteSystemById,
  enableSystem,
  updateSystem,
  getAllOSSystems,
  disableSystem,
  getAllBoomiSystems,
  addBoomiSystem,
  updateOSSystem,
  updateBoomiSystem,
  deleteOSSystemById,
  deleteBoomiSystemById,
  getOSSystemById,
  getBoomiSystemById
};

export default systemsService;
