import { IconSpinner } from "../../_Catalog/icons";

const InfoSpanField = ({ loadedData, name, label, isLoading, type }) => {
  if (isLoading) {
    return (
      <div className="col-span-6 sm:col-span-3 w-full relative">
        {name && (
          <label htmlFor={label || name} className="block text-sm font-medium text-app-gray-700">
            {label || name} <span className="text-app-red-color">*</span>
          </label>
        )}
        <div className="flex justify-between bg-gray-200 dark:bg-secondary-color mt-1 w-full rounded-md border border-app-gray-300 shadow-sm sm:text-sm text-app-gray-700 h-[38px] p-2">
          -- Loading {name} --
          <div className="animate-spin h-5 w-5">
            <IconSpinner />
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="col-span-6 sm:col-span-3">
      {name && (
      <label htmlFor={label || name} className="block text-sm font-medium text-app-gray-700">
        {label || name} <span className="text-app-red-color">*</span>
      </label>
      )}
      <input type={type} disabled={true} value={loadedData || ''} className="mt-1 p-2 w-full shadow-sm rounded border-none dark:bg-secondary-color text-sm text-primary-text-color font-medium" />
    </div>
  );
};
export default InfoSpanField;