import httpMain from "../../../../core/utils/apis/httpMain";

const getSystemById = async (systemId) => {
  try {
    const response = await httpMain({
      method: 'GET',
      url: `/systems/${systemId}`
    });

    return response;
  } catch (e) {
    throw e;
  }
};

const getOSSystemById = async (systemId) => {
  try {
    const response = await httpMain({
      method: 'GET',
      url: `/os_connection/id/${systemId}`
    });

    return response;
  } catch (e) {
    throw e;
  }
};


const getBoomiSystemById = async (systemId) => {
  try {
    const response = await httpMain({
      method: 'GET',
      url: `/boomi_connection/${systemId}`
    });

    return response;
  } catch (e) {
    throw e;
  }
};



const systemDetailsService = {
  getSystemById,
  getOSSystemById,
  getBoomiSystemById
};

export default systemDetailsService;