import { useEffect } from 'react';

const useHideLoader = () => {
  useEffect(() => {
    const hideAppLoader = () => {
      const loader = document.getElementById('avxInitLoader');
      if (loader) {
        loader.classList.add('hiddenLoader');
      }
    };

    if (document.readyState === 'complete') {
      hideAppLoader();
    } else {
      window.addEventListener('load', hideAppLoader);
      return () => window.removeEventListener('load', hideAppLoader);
    }
  }, []);
};

export default useHideLoader;