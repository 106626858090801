import * as yup from 'yup';

const BoomiSystemSchema = yup.object().shape({
  name: yup.string().required('Name is required'),
  description: yup.string().required('Description is required'),
  atom_id: yup.string().required('AtomId is required'),
  account_id: yup.string().required('AccountId is required'),
  email: yup.string().required('Email is required'),
  password: yup.string().required('Passowrd is required'),
});

export default BoomiSystemSchema;