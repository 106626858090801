import React from "react";
import { format } from 'date-fns';
import InfoSpanField from "../../../../components/AppInputForm/infoSpanField/InfoInputField";

const BoomiSystemDetailsForm = ({ data, isLoading, error }) => {

    if (error) {
        return <div className="text-2xl text-app-red-color text-center">An error has occurred</div>;
    }

    return (
        <div className="md:col-span-2 md:mt-0">
            <form>
                <div className="overflow-x-auto rounded-xl border max-h-[80vh] bg-transparent-dark">
                    <div className="px-4 py-3 sm:p-6">
                        <div className="px-6 pb-6 border-b mb-3">
                            <span className="my-4 block text-xl text-app-gray-700">Connection Details</span>
                            <div className="grid grid-cols-6 gap-6 mt-2 mb-6">
                                <InfoSpanField name="Name" loadedData={data?.name} isLoading={isLoading} />
                                <InfoSpanField name="Description" loadedData={data?.description} isLoading={isLoading} />
                                <InfoSpanField name="Atom ID" loadedData={data?.atom_id} isLoading={isLoading} />
                                <InfoSpanField name="Account ID" loadedData={data?.account_id} isLoading={isLoading} />
                                <InfoSpanField name="Email" loadedData={data?.email} isLoading={isLoading} />
                                <InfoSpanField type="password" name="Password" loadedData={data?.password} isLoading={isLoading} />
                                <InfoSpanField name="Created At" loadedData={data?.create_at ? format(new Date(data.create_at), 'M/d/yyyy h:mm:ss a') : ''} isLoading={isLoading} />
                                <InfoSpanField name="Updated At" loadedData={data?.update_at ? format(new Date(data.update_at), 'M/d/yyyy h:mm:ss a') : ''} isLoading={isLoading} />
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default BoomiSystemDetailsForm;
