import { motion, useAnimationControls } from "framer-motion";
import { useState, useEffect } from "react";
import NavigationLink from "./NavigationLink";
import logo from "../../../assets/img/minotaur-logo.svg";
import { Link } from "react-router-dom";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import {
  IconSystem,
  IconTransaction,
  IconCalender,
  IconTask,
  IconFlow,
  IconChevronUp,
  IconChevroDown,
  CarbonMicroservices1,
  CarbonReportData,
  TablerReport,
  DeployedCode,
  IncidentIcon,
  CarbonNotificationNew,
  IlDashboard,
  Iconticket,
} from "../../../components/_Catalog/icons";
import useAppSide from "../logic/useAppSide";
import { useService } from "../../ServiceMenu/ServiceContext";
import useTicketing from "../../../pages/Settings/TicketingCenter/logic/useTicketing";

const containerVariants = {
  close: {
    width: "3rem",
    transition: {
      type: "spring",
      damping: 15,
      duration: 0.5,
    },
  },
  open: {
    width: "auto",
    transition: {
      type: "spring",
      damping: 15,
      duration: 0.5,
    },
  },
};

const svgVariants = {
  close: {
    rotate: 360,
  },
  open: {
    rotate: 180,
  },
};

const Navigation = () => {
  const { dispatcherToken, extractorToken, decisionToken } = useAppSide();

  const [isOpen, setIsOpen] = useState(true);
  const [openSubItems, setOpenSubItems] = useState({});
  const containerControls = useAnimationControls();
  const svgControls = useAnimationControls();
  const { showMenu, setShowMenu, selectedService, service } = useService();

  const handleServiceClick = () => {
    setShowMenu(!showMenu);
  };

  const toggleSubItems = (itemName) => {
    setIsOpen(true);
    setOpenSubItems((prevState) => ({
      ...prevState,
      [itemName]: !prevState[itemName],
    }));
  };

  useEffect(() => {
    if (
      selectedService === "/operation-flow/create-flow" ||
      selectedService.includes("/operation-flow-details/")
    ) {
      setIsOpen(false);
    }
  }, [selectedService]);

  useEffect(() => {
    if (isOpen) {
      containerControls.start("open");
      svgControls.start("open");
    } else {
      containerControls.start("close");
      svgControls.start("close");
      setOpenSubItems({});
    }
  }, [isOpen]); // eslint-disable-line

  const handleOpenClose = () => {
    setIsOpen(!isOpen);
  };

  const { ticketStatusTo } = useTicketing();

  const serviceNavigation = {
    HyperAutomation: [
      { name: "Automation Hub", to: "/tasks", icon: IconTransaction },
      { name: "Connections", to: "/connections", icon: IconSystem },
      { name: "Reporting", to: "/reporting", icon: CarbonReportData },
      { name: "Daily Report", to: "/settings/dailyReport", icon: TablerReport },
    ],
    "Incident Hub": [
      { name: "Dashboard", to: "/incident/dashboard", icon: IlDashboard },
      {
        name: "Ticketing Center",
        to: "/incident/ticketing-center",
        icon: IconTask,
      },
      {
        name: "Tickets Status",
        to: ticketStatusTo || "/tickets/default",
        icon: Iconticket,
      },
      {
        name: "Incident Escalation",
        icon: IncidentIcon,

        subItems: [
          {
            name: "Priority Management",
            to: "/incident/priority-management",
            icon: IconCalender,
          },
          {
            name: "Task Management",
            to: "/incident/task-management",
            icon: IconCalender,
          },
          {
            name: "Tier Management",
            to: "/incident/tier-management",
            icon: IconCalender,
          },
          {
            name: "Assignment Configurator",
            to: "/incident/assignment-config",
            icon: IconCalender,
          },
        ],
      },
      {
        name: "Notification Center",
        icon: CarbonNotificationNew,
        subItems: [
          { name: "Teams", to: "/incident/teams", icon: IconCalender },
          { name: "Email", to: "/incident/email", icon: IconCalender },
          { name: "SMS", to: "/incident/sms", icon: IconCalender },
        ],
      },  
    ],
    CortexFlow: [
      { name: "Builder", to: "/operation-flow", icon: IconFlow },
      { name: "Connections", to: "/cortexFlow/connections", icon: IconSystem },
      { name: "Reporting", to: "/cortex/reporting", icon: CarbonReportData },
      { name: "Deployment", to: "/cortex/deployment", icon: DeployedCode },
    ],
    "Boomi Calendar": [
      { name: "Environments", to: "/calendar/environments", icon: IlDashboard },
      {
        name: "Boomi Dashboard",
        to: "/calendar/boomidashboard",
        icon: Iconticket,
      },
      {
        name: "Interface Master",
        to: "/calendar/interfacemaster",
        icon: IncidentIcon,
      },
    ],

    Home: [],
  };
  const renderTooltip = (props, itemName) => (
    <Tooltip id="button-tooltip" {...props}>
      {itemName}
    </Tooltip>
  );

  const navigation = serviceNavigation[service] || [];

  if (!dispatcherToken || !extractorToken || !decisionToken) return null;

  return (
    <div className="sidebar relative over">
      <motion.nav
        variants={containerVariants}
        animate={containerControls}
        initial="close"
        className="flex flex-col justify-between z-10 gap-4 mx-3.5 my-2 h-screen "
      >


        <div className={`flex flex-col flex-grow overflow-auto overflow-x-hidden custom-scrollbar ${isOpen ? 'gap-10' : 'gap-15'}`}>
          <div className="flex flex-col">
            <Link
              to="/"
              className="flex items-center rounded cursor-pointer stroke-[0.75] hover:stroke-neutral-100 stroke-neutral-400 text-neutral-400 place-items-center gap-2 transition-colors duration-100"
            >
              <img
                className="stroke-inherit stroke-[0.75] min-w-[40px] w-[40px]"
                src={logo}
                alt="Minotaur"
              />
              <p className="text-inherit overflow-clip whitespace-nowrap tracking-wide">
                <span className="text-2xl font-medium text-blue-700">
                  MINO<span className="text-orange-600">TAUR</span>
                </span>
              </p>
            </Link>

            {isOpen && selectedService && (
              <div className="flex flex-row -mt-3">
                {service.includes("CortexFlow") && (
                  <span className="ml-11 text-sm text-orange-600 font-medium">
                    CortexFlow<span className="text-blue-700"> Director</span>{" "}
                  </span>
                )}
                {service.includes("HyperAutomation") && (
                  <span className="ml-12 text-xs text-orange-600 font-medium">
                    SAP Hyper<span className="text-blue-700"> Monitoring</span>{" "}
                  </span>
                )}
                {service.includes("Incident Hub") && (
                  <span className="ml-16 text-sm text-orange-600 font-medium">
                    Incident <span className="text-blue-700">Hub</span>{" "}
                  </span>
                )}
                {service.includes("Boomi Calendar") && (
                    <span className="ml-16 text-sm text-orange-600 font-medium">
                    Boomi <span className="text-blue-700">Calendar</span>{" "}
                  </span>)}
              </div>
            )}
          </div>
          {(service === "CortexFlow" ||
            service === "HyperAutomation" ||
            service === "Incident Hub" ||
            service === "Boomi Calendar") && (
            <div className="flex flex-col gap-3">
              {navigation.map((item, index) => (
                <div key={index}>
                  {!item.subItems ? (
                    <NavigationLink name={item.name} path={item.to}>
                      {!isOpen ? (
                        <OverlayTrigger
                          placement="right"
                          delay={{ show: 250, hide: 400 }}
                          overlay={(props) => renderTooltip(props, item.name)}
                        >
                          <div className="stroke-inherit stroke-[0.75] min-w-8 w-8">
                            <item.icon />
                          </div>
                        </OverlayTrigger>
                      ) : (
                        <div className="stroke-inherit stroke-[0.75] min-w-8 w-8">
                          <item.icon />
                        </div>
                      )}
                    </NavigationLink>
                  ) : (
                    <div className="ml-auto stroke-inherit stroke-[0.75]">
                      {!isOpen ? (
                        <OverlayTrigger
                          placement="right"
                          delay={{ show: 250, hide: 400 }}
                          overlay={(props) => renderTooltip(props, item.name)}
                        >
                          <div
                            className="flex items-center py-1 pl-2 rounded place-items-center gap-2 transition-colors duration-100 hover:bg-primary-bg-color text-primary-text-color"
                            onClick={() => toggleSubItems(item.name)}
                          >
                            <div className="flex items-center gap-3 cursor-pointer">
                              <item.icon />
                              <p className="text-[15px] font-medium overflow-clip whitespace-nowrap tracking-wide ">
                                {item.name}
                              </p>
                            </div>

                            <button
                              type="button"
                              className="flex items-center focus:outline-none"
                            >
                              {openSubItems[item.name] ? (
                                <IconChevronUp />
                              ) : (
                                <IconChevroDown />
                              )}
                            </button>
                          </div>
                        </OverlayTrigger>
                      ) : (
                        <div
                          className="flex items-center py-1 pl-2 rounded place-items-center gap-2 transition-colors duration-100 hover:bg-primary-bg-color text-primary-text-color"
                          onClick={() => toggleSubItems(item.name)}
                        >
                          <div className="flex items-center gap-3 cursor-pointer">
                            <item.icon />
                            <p className="text-[15px] font-medium overflow-clip whitespace-nowrap tracking-wide cursor-pointer">
                              {item.name}
                            </p>
                          </div>

                          <button
                            type="button"
                            className="flex items-center focus:outline-none"
                          >
                            {openSubItems[item.name] ? (
                              <IconChevronUp />
                            ) : (
                              <IconChevroDown />
                            )}
                          </button>
                        </div>
                      )}

                      {isOpen && openSubItems[item.name] && (
                        <div className="ml-10 flex flex-col gap-3 mt-2">
                          {item.subItems.map((subItem, subIndex) => (
                            <NavigationLink
                              key={subIndex}
                              name={subItem.name}
                              path={subItem.to}
                            ></NavigationLink>
                          ))}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
        {!isOpen ? (
          <OverlayTrigger
            placement="right"
            delay={{ show: 250, hide: 250 }}
            overlay={(props) => renderTooltip(props, "Services")}
          >
            <button
              className="flex justify-center items-center rounded-md hover:bg-primary-bg-color w-full h-9 dark:text-gray-200"
              onClick={handleServiceClick}
            >
              <CarbonMicroservices1 />
            </button>
          </OverlayTrigger>
        ) : (
          <button
            className="flex items-center gap-3 rounded-md hover:bg-primary-bg-color dark:text-gray-200 w-full h-10"
            onClick={handleServiceClick}
          >
            <CarbonMicroservices1 />
            <span className="font-medium">Services</span>
          </button>
        )}

        <button
          className="rounded-full flex items-center mb-5"
          onClick={handleOpenClose}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="#2460c2"
            viewBox="0 0 24 24"
            className="w-10 h-10"
          >
            <motion.path
              strokeLinecap="round"
              strokeLinejoin="round"
              variants={svgVariants}
              animate={svgControls}
              d="m6 17l5-5l-5-5m7 10l5-5l-5-5"
              transition={{
                duration: 0.5,
                ease: "easeInOut",
              }}
            />
          </svg>

          {isOpen && (
            <p className="text-sm font-medium text-blue-700 ">Close SideBar</p>
          )}
        </button>
      </motion.nav>
    </div>
  );
};

export default Navigation;
