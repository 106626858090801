import React from "react";
import { IconSpinner } from "../_Catalog/icons";

const AppLoader = () => {
  return (
    <div className="h-full w-full inset-0 bg-transparent bg-opacity-90 z-50 flex items-center justify-center" >
      <div className="animate-spin h-20 w-20">
        <IconSpinner />
      </div>
    </div>
  )
};

export default AppLoader;