import * as Yup from 'yup';


const SAPSystemSchema = Yup.object().shape({
  sid: Yup.string().required('SID is required').length(3, 'SID must be exactly 3 characters long'),
  description: Yup.string().required('Connection name  is required'),
  language: Yup.string().required('Language is required'),
  client: Yup.number().transform((value, originalValue) => (originalValue === '' ? null : value)).nullable().required('Client is required'),
  category: Yup.number().transform((value, originalValue) => (originalValue === '' ? null : value)).nullable().required('Category is required'),
  environment: Yup.number().transform((value, originalValue) => (originalValue === '' ? null : value)).nullable().required('Environment is required'),
  instances: Yup.object().shape({
    instance_number: Yup.string().required('Instance number is required'),
    host: Yup.string().required('Host is required'),
    description: Yup.string().required('Instance description is required'),
  }).required('Instance information is required'),
  basis_user: Yup.object().shape({
    username: Yup.string().required('User is required'),
    password: Yup.string().required('Password is required'),
  }).required('Basis user information is required'),
});

export default SAPSystemSchema;
