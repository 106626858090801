import httpNotif from "../../../../core/utils/apis/httpNotif";


const getTool = async () => {
    try {
        const response = await httpNotif({
            method: "GET",
            url: `/systemchoice/`,
        });
        return response.data;
    } catch (e) {
        throw e;
    }
};

const updateTool = async (id, reqBody) => {
    try {
        const response = await httpNotif({
            method: "PUT",
            url: `/systemchoice/${id}`,
            data: reqBody,
        });
        return response;
    } catch (e) {
        console.error("Error updating Tool:", e);
        throw e;
    }
};

const TicketingServices = {
    getTool,
    updateTool,
};

export default TicketingServices;
