import React from "react";
import InfoSpanField from "../../../../components/AppInputForm/infoSpanField/InfoInputField";

const SAPSystemDetailsForm = ({ data, isLoading, error }) => {
  if (error) return <div className="text-2xl text-app-red-color text-center">An error has occured</div>
  return (
    <div className="md:col-span-2 md:mt-0">
      <form>
        <div className="overflow-x-auto rounded-lg border max-h-[80vh] bg-transparent-dark custom-scrollbar">
          <div className="px-4 py-3 sm:p-6">
            <div className="px-6 pb-6 border-b mb-3">
              <span className="my-4 block text-xl text-app-gray-700">Connection Details</span>
              <div className="grid grid-cols-6 gap-6 mt-2 mb-6">
                <InfoSpanField name="SID" loadedData={data?.sid} isLoading={isLoading} />
                <InfoSpanField name="Description" loadedData={data?.description} isLoading={isLoading} />
                <InfoSpanField name="Environment" loadedData={data?.system_env_name} isLoading={isLoading} />
                <InfoSpanField name="Category" loadedData={data?.system_category_name} isLoading={isLoading} />
                <InfoSpanField name="Client" loadedData={data?.client} isLoading={isLoading} />
                <InfoSpanField name="Language" loadedData={data?.lang} isLoading={isLoading} />
              </div>
            </div>
            <div className="px-6 pb-6 border-b mb-3">
              <span className="my-4 block text-xl text-app-gray-700">Instance details</span>
              <div className="grid grid-cols-6 gap-6 mt-2 mb-6">
                {isLoading ? (
                  <>
                    <InfoSpanField name="Instance number" isLoading={isLoading} />
                    <InfoSpanField name="Instance host" isLoading={isLoading} />
                    <InfoSpanField name="Instance description" isLoading={isLoading} />
                  </>
                ) : (
                  data?.instances.map((instance, index) => (
                    <React.Fragment key={index}>
                      <InfoSpanField name="Instance number" loadedData={instance.instance_number} />
                      <InfoSpanField name="Instance host" loadedData={instance.host} />
                      <InfoSpanField name="Instance description" loadedData={instance.description} />
                    </React.Fragment>
                  ))
                )}
              </div>
            </div>
            <div className="px-6 pb-6 rounded">
              <span className="my-4 block text-xl text-app-gray-700">User credentials</span>
              <div className="grid grid-cols-6 gap-6 mt-2 mb-6">
                {isLoading ? (
                  <>
                    <InfoSpanField name="Username" isLoading={isLoading} />
                    <InfoSpanField name="Password" isLoading={isLoading} />
                    <InfoSpanField name="User Type" isLoading={isLoading} />
                  </>
                ) : (
                  data?.system_credentials.map((credential, index) => (
                    <React.Fragment key={index}>
                      <InfoSpanField name="Username" loadedData={credential.username} />
                      <InfoSpanField type="password" name="Password" loadedData={credential.password} />
                      <InfoSpanField name="User Type" loadedData={credential.user_type} />
                    </React.Fragment>
                  ))
                )}
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default SAPSystemDetailsForm;
